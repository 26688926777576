import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { Box } from '@mui/material';

/*---CVT---*/
import { CvtColorSchema, CvtBasicTheme } from '../../global/cvt-obj';
import { DeviceSizes } from '../../global/global-obj';
import { BlockTop } from '../../components/cvt/layout/block-top';
import { BlockStandard } from '../../components/cvt/layout/block-standard';
import { HtmlContent } from '../../components/basic/html-content';
import { BulletList } from '../../components/cvt/data-display/bullet-list';

/*---Components---*/
import Layout from '../../components/skeleton_page/layout';
import Seo from '../../components/skeleton_page/seo';
import PageblockPhotoHalf from '../../components/pageBlockPhotoHalf';

/*---Objects---*/
import { PaddingObj, DiagonalObj } from '../../global/sharedObjects';

/*---Images---*/
import BulletpointWeiss from '../../images/icons/strich_weiss.svg';

import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

export const AutomatisierungPageTemplate = ({
  title,
  subtitle,
  image,
  automatisationOverview,
  automatisationBullets,
  automatisationTools,
  automatisationOffer,
  automatisationStory,
  automatisationSpotlight,
}: {
  title: string;
  subtitle: string;
  image: any;
  automatisationOverview: any;
  automatisationBullets: any;
  automatisationTools: any;
  automatisationOffer: any;
  automatisationStory: any;
  automatisationSpotlight: any;
}) => {
  return (
    <section className="section section--gradient">
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h1
          style={{
            color: CvtColorSchema.bgDarkGray,
          }}
        >
          {title}
        </h1>
        <h3
          style={{
            color: CvtColorSchema.bgDarkGray,
          }}
        >
          {subtitle}
        </h3>
      </div>
      <hr />
      <h2>{automatisationOverview.headline}</h2>
      <HtmlContent
        dangerouslySetInnerHTML={md.render(automatisationOverview.content)}
        style={CvtBasicTheme.htmlContent.general}
      />
      <hr />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: CvtColorSchema.bgDarkGray,
          color: CvtColorSchema.white,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {automatisationBullets.bulletlist.map((item: any) => {
            return (
              <>
                <h3>{item.headline}</h3>
              </>
            );
          })}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <HtmlContent
            dangerouslySetInnerHTML={md.render(automatisationBullets.content)}
            style={CvtBasicTheme.htmlContent.general}
          />
        </div>
      </div>
      <hr />
      <h2>{automatisationTools.headline}</h2>
      <HtmlContent
        dangerouslySetInnerHTML={md.render(automatisationTools.content)}
        style={CvtBasicTheme.htmlContent.general}
      />
      <hr />
      <h2>{automatisationOffer.headline}</h2>
      <h3>{automatisationOffer.subheadline}</h3>
      <HtmlContent
        dangerouslySetInnerHTML={md.render(automatisationOffer.content)}
        style={CvtBasicTheme.htmlContent.general}
      />
      <hr />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: CvtColorSchema.bgGray,
          color: CvtColorSchema.fontDarkGray,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <img
            src={automatisationStory.image}
            alt="Unternehmensgruppe"
            width="200px"
            height="auto"
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h2>{automatisationStory.headline}</h2>
          <HtmlContent
            dangerouslySetInnerHTML={md.render(automatisationStory.content)}
            style={CvtBasicTheme.htmlContent.general}
          />
        </div>
      </div>
      <hr />
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!automatisationSpotlight.image.childImageSharp
              ? automatisationSpotlight.image.childImageSharp.fluid.src
              : automatisationSpotlight.image
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h3
          style={{
            color: CvtColorSchema.fontBlack,
          }}
        >
          {automatisationSpotlight.subheadline}
        </h3>
        <h2
          style={{
            color: CvtColorSchema.fontBlack,
          }}
        >
          {automatisationSpotlight.headline}
        </h2>
        <HtmlContent
          dangerouslySetInnerHTML={md.render(automatisationSpotlight.content)}
          style={CvtBasicTheme.htmlContent.general}
        />
      </div>
    </section>
  );
};

AutomatisierungPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  automatisationOverview: PropTypes.shape({
    headline: PropTypes.string,
    content: PropTypes.string,
  }),
  automatisationBullets: PropTypes.shape({
    content: PropTypes.string,
    bulletlist: PropTypes.array,
  }),
  automatisationTools: PropTypes.shape({
    headline: PropTypes.string,
    content: PropTypes.string,
  }),
  automatisationOffer: PropTypes.shape({
    headline: PropTypes.string,
    subheadline: PropTypes.string,
    content: PropTypes.string,
  }),
  automatisationStory: PropTypes.shape({
    headline: PropTypes.string,
    image: PropTypes.string,
    content: PropTypes.string,
  }),
  automatisationSpotlight: PropTypes.shape({
    headline: PropTypes.string,
    subheadline: PropTypes.string,
    image: PropTypes.string,
    content: PropTypes.string,
  }),
};

const AutomatisierungPage = ({ data }: { data: any }) => {
  const { frontmatter } = data.markdownRemark;
  const location = useLocation();
  const featuredImage = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.resize
    : null;

  const styles = {
    container: {
      whiteLi: {
        '& > div > ul > li': {
          background: `url(${BulletpointWeiss}) no-repeat 0px 4px`,
          backgroundSize: '12px',
          paddingLeft: '25px',
        },
      },
      flex: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > div > ul': {
          display: 'flex',
          flexDirection: 'column',
        },
        '& > div > ul > li': {
          alignSelf: 'flex-end',
        },
      },
      bulletlist: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          [`@media (max-width: ${DeviceSizes.laptop})`]: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
          },
        },
        bullets: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          margin: '0 1em 0 0',
          [`@media (max-width: ${DeviceSizes.laptop})`]: {
            margin: '0 0 3em 0',
          },
        },
        content: {
          width: '40%',
          [`@media (max-width: ${DeviceSizes.laptop})`]: {
            width: '100%',
          },
        },
      },
    },
  } as const;

  return (
    <Layout>
      <Seo
        title={frontmatter.pageTitle}
        keywords={frontmatter.seoKeywords}
        description={frontmatter.pageDescription}
        image={featuredImage}
        pathname={location.pathname}
      />
      <BlockTop
        headline={frontmatter.title}
        subheadline={frontmatter.subtitle}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgImage={
          !frontmatter.image.childImageSharp &&
          frontmatter.image.extension === 'svg'
            ? frontmatter.image.publicURL
            : null
        }
        zIndex={10}
        padding={PaddingObj.PaddingStarter}
        color={CvtColorSchema.fontDarkGray}
      />
      <BlockStandard
        headline={frontmatter.automatisationOverview.headline}
        bgColor={CvtColorSchema.white}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={9}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.fontDarkGray}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.automatisationOverview.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
      </BlockStandard>
      <BlockStandard
        bgColor={CvtColorSchema.bgDarkGray}
        padding={PaddingObj.PaddingIdle}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={8}
        color={CvtColorSchema.white}
      >
        <Box component="div" sx={styles.container.bulletlist.root}>
          <Box component="div" sx={styles.container.bulletlist.bullets}>
            <BulletList
              list={frontmatter.automatisationBullets.bulletlist}
              alignBulletPoints={'right'}
            />
          </Box>
          <Box component="div" sx={styles.container.bulletlist.content}>
            <HtmlContent
              dangerouslySetInnerHTML={md.render(
                frontmatter.automatisationBullets.content
              )}
              style={CvtBasicTheme.htmlContent.general}
            />
          </Box>
        </Box>
      </BlockStandard>

      <BlockStandard
        headline={frontmatter.automatisationTools.headline}
        bgColor={CvtColorSchema.white}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={7}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.fontDarkGray}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.automatisationTools.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
      </BlockStandard>
      <BlockStandard
        headline={frontmatter.automatisationOffer.headline}
        subheadline={frontmatter.automatisationOffer.subheadline}
        bgColor={CvtColorSchema.yellow}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={6}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.fontDarkGray}
        textAlignment={CvtBasicTheme.align.right as any}
      >
        <Box component="div" sx={styles.container.flex}>
          <HtmlContent
            dangerouslySetInnerHTML={md.render(
              frontmatter.automatisationOffer.content
            )}
            style={CvtBasicTheme.htmlContent.general}
          />
        </Box>
      </BlockStandard>
      <PageblockPhotoHalf
        headline={frontmatter.automatisationStory.headline}
        bgcolor={CvtColorSchema.bgGray}
        indexZ="5"
        imgposition="left"
        image={
          !frontmatter.automatisationStory.image.childImageSharp &&
          frontmatter.automatisationStory.image.extension === 'jpg'
            ? frontmatter.automatisationStory.image.publicURL
            : frontmatter.automatisationStory.image.publicURL
        }
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.automatisationStory.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
      </PageblockPhotoHalf>
      <BlockStandard
        headline={frontmatter.automatisationSpotlight.headline}
        subheadline={frontmatter.automatisationSpotlight.subheadline}
        bgImage={
          !frontmatter.automatisationSpotlight.image.childImageSharp &&
          frontmatter.automatisationSpotlight.image.extension === 'svg'
            ? frontmatter.automatisationSpotlight.image.publicURL
            : null
        }
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={4}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.fontBlack}
      >
        <Box component="div" sx={styles.container.whiteLi}>
          <HtmlContent
            dangerouslySetInnerHTML={md.render(
              frontmatter.automatisationSpotlight.content
            )}
            style={CvtBasicTheme.htmlContent.general}
          />
        </Box>
      </BlockStandard>
    </Layout>
  );
};

AutomatisierungPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AutomatisierungPage;

export const automatisierungPageQuery = graphql`
  query AutomatisierungPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        automatisationOverview {
          content
          headline
        }
        automatisationBullets {
          content
          bulletlist {
            headline
            text
          }
        }
        automatisationTools {
          headline
          content
        }
        automatisationOffer {
          headline
          subheadline
          content
        }
        automatisationStory {
          headline
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
          content
        }
        automatisationSpotlight {
          headline
          subheadline
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
          content
        }
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          extension
          publicURL
        }
        seoKeywords
        pageTitle
        pageDescription
        subtitle
        title
        featuredImage {
          childImageSharp {
            resize(width: 900) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`;
